import Layout from '../components/common/Layout';
import Navbar from '../components/common/Navbar';
import Footer from '../components/sections/Footer';
import React from 'react';
import Careers from '../components/sections/Careers';

const CareersPage = () => (
    <Layout>
        <Navbar/>
        <div class="main-content">
        <Careers/>
        </div>
        <Footer/>
    </Layout>
);

export default CareersPage;
