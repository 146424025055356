import React from 'react';
import { Container, Section } from '../global';
import styled from 'styled-components';
import CareerInfo from './CareerContent/CareerInfo';
import CareerTraits from './CareerContent/CareerTraits';
import CareerGrowth from './CareerContent/CareerGrowth';
import CareerJobOpenings from './CareerContent/CareerJobOpenings';

const Careers = () => (
  <Section id="careers">
    <Container>
    <h1>CAREERS</h1>
    <CareerInfo/>
    <CareerTraits/>
    <CareerGrowth/>
    <CareerJobOpenings/>
    </Container>
    <h1>Fill Out The Form Below To Apply!</h1>
    <StyledContainer>
      <iframe
        title="Career Application"
        src="https://docs.google.com/forms/d/e/1FAIpQLSeVQmWyGtDptpm8Te4f_XlnxAac2n_nW5Q-yaKL1BJ4BuF8qA/viewform?embedded=true"
        width="640"
        height="2100"
      >
        Loading…
      </iframe>
    </StyledContainer>
  </Section>
);

const StyledContainer = styled(Container)`
  display: flex;
  position: relative;
  justify-content: center;
`;

export default Careers;
