import React from 'react';
import { Container, Section } from '../../global';

const CareerJobOpenings = () => (
  <Section id="career-job-openings">
    <Container>
            <h2>Current Job Openings</h2>
            <ul>
                <li>See <a href="https://www.indeed.com/cmp/Wonder-Years-Academy/jobs">indeed</a> for the most up to date openings.</li>
                <li>Full-Time Floater - No current openings at this time.</li>
                <li>Part-Time Floater - No current openings at this time.</li>
                <li>Teacher’s Assistant - <a href="https://www.indeed.com/viewjob?jk=dbb05402fa7b9232&tk=1fd8k6i49u38s801">one opening</a>.</li>
                <li>Lead Teacher - <a href="https://www.indeed.com/viewjob?jk=c0de4bfec8eec1d1&tk=1fd8k6i49u38s801">one opening</a>.</li>
                <li>Food Specialist - No current openings at this time.</li>
            </ul>
        </Container>
  </Section>
);

export default CareerJobOpenings;
