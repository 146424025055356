import React from 'react';
import { Container, Section } from '../../global';

const CareerTraits = () => (
  <Section id="career-traits">
    <Container>
            <h2>Traits We Are Looking For</h2>
            <ul>
                <li>- Flexible  </li>
                <li>- Handle stressful situations. </li>
                <li>- Articulate communication both written and verbal.</li>
                <li>- Basic understanding of child development.</li>
            </ul>
        </Container>
  </Section>
);

export default CareerTraits;
