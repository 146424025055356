import React from 'react';
import { Container, Section } from '../../global';

const CareerGrowth = () => (
  <Section id="career-growth">
    <Container>
      <h2>Opportunities For Growth </h2>
      <ul>
        <li>
          - Financially supported in obtaining the following education
          <ul>
            <li>
              - Child Development Credential (CDA) - required to obtain within
              the first 2 years of employment
            </li>
            <li>- Associate of Arts in Early Childhood Education</li>
            <li>- Bachelor’s Degree in Early Childhood Education</li>
          </ul>
        </li>
        <li>- Become First Aid and CPR certified.</li>
        <li>- Obtain Universal Precaution training.</li>
        <li>- Education Workshops.</li>
      </ul>
    </Container>
  </Section>
);

export default CareerGrowth;
