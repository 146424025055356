import React from 'react';
import { Container, Section } from '../../global';

const CareerInfo = () => (
  <Section id="career-info">
    <Container>
      <ul>
        <li>
          Wonder Years Academy believes in building positive relationships with
          our families. This allows open communication in our children’s
          education. Our low teacher to child ratio promotes an environment for
          individualized care. Which will result in a balance of academic and
          social development, physical well being and creative expression.
        </li>
      </ul>
    </Container>
  </Section>
);

export default CareerInfo;
